<template >
  <div class="containter">
    <div class="commentArea">
      <div class="fieldArea">
        <van-field
          v-model="commentText"
          rows="5"
          maxlength="100"
          show-word-limit
          autosize
          type="textarea"
          style="height:100%"
          placeholder="请输入您想评论的内容"
        />
      </div>
      <div class="imgLine">
        <!-- <img :src="addImg" class="addImg" /> -->
        <van-uploader
          class="marginLeft12"
          v-model="fileList"
          :before-read="beforeRead"
          :after-read="afterRead"
          upload-text="添加图片"
          result-type="dataUrl"
          :max-count="3"
          multiple
        >
          
        </van-uploader>
      </div>
    </div>
    <div class="submitButton" @click="submit">发布</div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { uploadFile } from '../../api/mainPage';
import { commentsPost } from '../../api/activity';
import qs from 'qs';
import * as dd from 'dingtalk-jsapi';

export default {
  name: 'ActivityComment',

  data() {
    return {
      addImg: require('./imgs/addImg.png'),
      id: '',
      commentText: '',
      fileList: [],
      isWeixin:false
    };
  },
  watch: {},
  created() {
      dd.biz.navigation.setTitle({
        title: '评论', //控制标题文本，空字符串表示显示默认文本
        onSuccess: function(result) {
          /*结构
          {
          }*/
        },
        onFail: function(err) {}
      });
    this.getRecords();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const { id } = this.$route.query;
      this.id = id;
    },
    addPhoto(){

    },
    // 上传前校验
    beforeRead(file) {
      console.log(this.fileList);
      let a= ''
      if (Array.isArray(file)) {
        let notType = false;
        file.forEach(item => {
          if (
            item.type !== 'image/jpeg' &&
            item.type !== 'image/png' &&
            item.type !== 'image/gif'
          ) {
            notType = true;
          }
        });
        if (notType) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
        if (file.length > 3 - this.fileList.length) {
          Notify({
            type: 'warning',
            message: '您上传的张数超过上限，请重新选择'
          });
          return false;
        }
      } else {
        if (
          file.type !== 'image/jpeg' &&
          file.type !== 'image/png' &&
          file.type !== 'image/gif'
        ) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
      }

      return true;
    },
    // 上传服务器
    afterRead(file) {
      const this_ = this;
      if (Array.isArray(file)) {
        file.forEach(fileItem => {
          console.log(fileItem, this.fileList);
          fileItem.status = 'uploading';
          fileItem.message = '上传中...';
          const formdata = new FormData();
          formdata.append('file', file.file);
          lrz(fileItem.file, {})
            .then(async function(rst) {
              //成功时执行
              console.log('压缩成功');
              const formdata = rst.formData;
              await uploadFile(formdata)
                .then(res => {
                  res = res.data;
                  if (res.success) {
                    fileItem.status = 'done';
                    fileItem.url = res.data.link;
                    Notify({ type: 'success', message: '上传成功' });
                  } else {
                    fileItem.status = 'failed';
                    fileItem.message = '上传失败';
                    Notify({ type: 'warning', message: '上传失败' });
                  }
                })
                .catch(err => {
                  fileItem.status = 'failed';
                  fileItem.message = '上传失败';
                  Notify({ type: 'warning', message: '上传失败' });
                  console.log(err);
                });
            })
            .catch(function(error) {
              Notify({ type: 'warning', message: '图片压缩失败' });
              //失败时执行
            })
            .always(function() {
              //不管成功或失败，都会执行
            });
        });
      } else {
        console.log(file, this.fileList);
        file.status = 'uploading';
        file.message = '上传中...';
        // const formdata = new FormData();
        // formdata.append('file', file.file);
        lrz(file.file, {})
          .then(function(rst) {
            //成功时执行
            console.log('压缩成功');
            const formdata = rst.formData;
            uploadFile(formdata)
              .then(res => {
                res = res.data;
                if (res.success) {
                  file.status = 'done';
                  file.url = res.data.link;
                  Notify({ type: 'success', message: '上传成功' });
                } else {
                  this_.fileList = this_.fileList.slice(
                    0,
                    this_.fileList.length - 1
                  );
                  Notify({ type: 'warning', message: '上传失败' });
                }
              })
              .catch(err => {
                this_.fileList = this_.fileList.slice(
                  0,
                  this_.fileList.length - 1
                );
                Notify({ type: 'warning', message: '上传失败' });
                console.log(err);
              });
          })
          .catch(function(error) {
            Notify({ type: 'warning', message: '图片压缩失败' });
            //失败时执行
          })
          .always(function() {
            //不管成功或失败，都会执行
          });
      }
    },
    submit() {
      const commentText = this.commentText;
      const fileList = this.fileList;
      const { id } = this.$route.query;
      if (!commentText) {
        Notify({ type: 'warning', message: '评论文字不能为空' });
        return;
      }
      let imgString = [];
      fileList.forEach(item => {
        if ((item.status = 'done')) {
          imgString.push(item.url);
        }
      });
      const imgArray = [];
      imgString.forEach(item => {
        if (item) {
          imgArray.push(item);
        }
      });
      console.log(imgArray);
      commentsPost({
        objectId: id,
        praiseType: 'ACTIVITY_TYPE',
        reviewContent: commentText,
        reviewImages: imgArray.join(',')
      })
        .then(res => {
          if (res.success) {
            Notify({ type: 'success', message: '评论成功' });
            this.$router.go(-1);
          } else {
            file.status = 'failed';
            file.message = '上传失败';
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
/deep/ .van-field__control {
  height: 120 * $px !important;
  overflow-y: auto;
}
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.commentArea {
  width: 100%;
  height: 265 * $px;
  background-color: #ffffff;
  position: relative;
}
.imgLine {
  width: 100%;
  height: 90 * $px;
  position: absolute;
  bottom: 10 * $px;
  left: 0px;
}
.marginLeft12 {
  margin-left: 12 * $px;
}
.addImg {
  width: 80 * $px;
  height: 80 * $px;
}
.submitButton {
  margin: 30 * $px 15 * $px;
  width: 345 * $px;
  height: 44 * $px;
  border-radius: 22 * $px;
  text-align: center;
  line-height: 44 * $px;
  background-color: #ff192f;
  color: #ffffff;
  font-size: 16 * $px;
}
.fieldArea {
  width: 100%;
  height: 160 * $px;
}
</style>



